<template>
  <div class="main-container-bg">
    <div class="main-container">
      <div class="header-container">
        <div class="header">
          <!-- HEADER ONLY MOBILE -->
          <div class="header-mobile">
            <SideMenu @createWalletLink="redirectToRegister" />
          </div>

          <!-- LOGO DESKTOP -->
          <div v-if="partnerLogo !== ''">
            <img
              class="partner-logo-dxa-top"
              :src="gs.get_photo_path(partnerLogo)"
              alt="logo"
            />
          </div>
          <div v-else class="dxa-logo-top">
            <img
              style="width: 126px !important"
              src="../../../../../../assets/dealboard-logo-white.svg"
            />
          </div>

          <div class="button-group">
            <span class="login-in-link" @click="handleGoAuth()">{{
              $t("login_2")
            }}</span>
            <HeaderMenuLink
              target="_self"
              :text="$t('get_started')"
              type="filled"
              class="create-wallet"
              @btnFunction="redirectToRegister"
            />
            <div style="z-index: 999; margin-left: 20px">
              <LanguageSwitch :lang="lang" />
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <section class="section-1-container">
          <div class="section1">
            <div class="section1-content">
              <div class="header-text">
                <h2 class="title">
                  {{ $t("invest_best_companies") }}
                </h2>
              </div>
              <p class="header-subtitle">
                {{ $t("start _investing_private_companies") }}
              </p>
              <div class="redirect-button-container">
                <HeaderMenuLink
                  @btnFunction="redirectToRegister"
                  target="_self"
                  :text="$t('meet_opportunities')"
                  type="filled"
                  class="link-filled-size"
                  data-test="alphaUser:createWalletBtn"
                />
              </div>
            </div>

            <!-- card-container-desktop -->
            <div class="card-container">
              <div class="header-label">
                <h2 class="card-header-title">
                  {{ $t("landing_graphic_title") }}
                </h2>
                <div class="card-header-text">
                  <p v-html="$t('landing_graphic_sub_title')"></p>
                </div>
                <div class="graphic-container">
                  <!-- SVG -->
                  <div>
                    <img
                      src="../../../../../../assets/welcome-page-logo-desktop.svg"
                      alt="Dealboard"
                      class="dealboard-logo-desktop"
                    />
                  </div>
                  <!-- INFO -->
                  <!-- source -->
                  <div class="graphic-info-container">
                    <!-- source -->
                    <span class="graphic-source">
                      <font-awesome-icon
                        icon="fa-solid fa-info"
                        class="graphic-source-info-icon"
                      />
                      {{ $t("investor_welcome_page_graphic_label_3") }}</span
                    >
                    <div class="graphic-info-footer">
                      <div class="graphic-info-footer-label">
                        <span class="circle blue" />
                        <span class="text">{{
                          $t("investor_welcome_page_graphic_label_1")
                        }}</span>
                      </div>
                      <div class="graphic-info-footer-label">
                        <span class="circle gray" />
                        <span class="text">{{
                          $t("investor_welcome_page_graphic_label_2")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- card container mobile -->
          <div class="card-container-mobile">
            <div class="header-label">
              <h2 class="card-header-title">
                {{ $t("landing_graphic_title") }}
              </h2>
              <div class="card-header-text">
                <p v-html="$t('landing_graphic_sub_title')"></p>
              </div>
              <div class="graphic-container">
                <!-- SVG -->
                <div class="graphic-image-container">
                  <div class="graphic-image-content">
                    <img
                      src="../../../../../../assets/welcome-page-logo-mobile.svg"
                      alt="Dealboard Gráfico"
                      class="dealboard-logo-mobile"
                    />
                    <!-- source -->
                    <span class="graphic-source">
                      <font-awesome-icon
                        icon="fa-solid fa-info"
                        class="graphic-source-info-icon"
                      />
                      {{ $t("investor_welcome_page_graphic_label_3") }}</span
                    >
                  </div>
                </div>
                <!-- INFO -->
                <!-- source -->
                <div class="graphic-info-container">
                  <div class="graphic-info-footer">
                    <div class="graphic-info-footer-label">
                      <span class="circle blue" />
                      <span class="text">{{
                        $t("investor_welcome_page_graphic_label_1")
                      }}</span>
                    </div>
                    <div class="graphic-info-footer-label">
                      <span class="circle gray" />
                      <span class="text">{{
                        $t("investor_welcome_page_graphic_label_2")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section-2-container">
          <div class="number-container-logo-container">
            <div class="number-container-logo">
              <div class="content">
                <div
                  v-if="partnerLogo !== ''"
                  class="number-group-dxa-partner-logo"
                >
                  <img
                    class="partner-logo-dxa"
                    :src="gs.get_photo_path(partnerLogo)"
                    alt="logo"
                  />
                </div>
                <div v-else class="number-group-dxa-logo">
                  <img
                    style="width: 126px !important"
                    src="../../../../../../assets/dealboard-logo-white.svg"
                  />
                </div>
                <span class="number-group-dxa-logo-sub-title">{{
                  $t("official_private_partner")
                }}</span>
              </div>
            </div>
          </div>
          <div class="section2">
            <div class="section2-content">
              <div class="number-group">
                <div class="number-container">
                  <span class="number">{{
                    $t("landing_value_under_management")
                  }}</span>
                  <span class="number-label">
                    {{ $t("landing_value_label_under_management") }}
                  </span>
                </div>
                <div class="number-container">
                  <span class="number">{{
                    $t("landing_value_global_investor")
                  }}</span>
                  <span class="number-label">
                    {{ $t("landing_value_label_global_investor") }}
                  </span>
                </div>
                <div class="number-container">
                  <span class="number">{{
                    $t("landing_value_minimum_contribution")
                  }}</span>
                  <span class="number-label">
                    {{ $t("landing_value_label_minimum_contribution") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section4">
          <span class="steps-label">{{
            $t("landing_step_by_step_title")
          }}</span>
          <div class="steps-row">
            <div class="step-container">
              <div class="step-number-container">
                <span class="step-number">1</span>
              </div>
              <span class="step-label">{{ $t("create_wallet") }}</span>
              <span class="step-description">{{
                $t("dealboard_landing_step_by_step_1")
              }}</span>
            </div>
            <div class="step-container">
              <div class="step-number-container">
                <span class="step-number">2</span>
              </div>
              <span class="step-label">
                {{ $t("dealboard_landing_title_step_by_step_1") }}
              </span>
              <span class="step-description">
                {{ $t("dealboard_landing_step_by_step_2") }}
              </span>
            </div>
            <div class="step-container">
              <div class="step-number-container">
                <span class="step-number">3</span>
              </div>
              <span class="step-label">
                {{ $t("dealboard_landing_title_step_by_step_2") }}
              </span>
              <span class="step-description">
                {{ $t("dealboard_landing_step_by_step_3") }}
              </span>
            </div>
          </div>

          <div class="redirect-footer-container">
            <HeaderMenuLink
              target="_self"
              :text="$t('meet_opportunities')"
              type="filled"
              class="link-filled-size"
              @btnFunction="redirectToRegister"
            />
          </div>
        </section>
        <footer class="footer">
          <div class="footer-row">
            <div class="footer-logos">
              <div>
                <img
                  alt="Dealboard"
                  src="../../../../../../assets/dealboard-logo-black.svg"
                />
              </div>
            </div>
            <div class="footer-label-containter">
              <span class="footer-label">{{
                $t("landing_better_way_to_invest")
              }}</span>
              <span
                @click="handleGoLinkedin()"
                class="linkedin-icon"
                href="https://br.linkedin.com/company/dxainvest"
                target="_self"
              >
                <img
                  class="social-logo"
                  src="../../../../../../assets/linkedin-gray.svg"
                />
              </span>
            </div>
          </div>
          <span class="footer-c"
            >{{ $t("dxa_all_rights_reserved_current_point") }}
            <span @click="handlePrivacyPolicy()" class="link">
              {{ $t("privacy_policy") }}</span
            >
          </span>
        </footer>
      </div>

      <v-dialog
        persistent
        v-model="chooseRegisterDialog"
        v-if="envAllowedRegisterInternational === 'TRUE'"
        class="dialog"
        scrollable
      >
        <v-card color="white" :class="gs.isMobile() ? '' : 'dxa_modal'">
          <div class="choose-register-dialog-header">
            <v-icon color="black" @click="chooseRegisterDialog = false"
              >mdi-close</v-icon
            >
          </div>
          <v-card-title>
            <h4 class="dxa_modal_title h4 text-center">
              {{ $t("investment_desire") }}
            </h4>
            <p class="info-choose-register">
              {{ $t("select_investor_register_title") }}
            </p>
          </v-card-title>
          <v-card-text>
            <div class="button-choose-register-container">
              <v-btn
                color="primary"
                data-test="WelcomePage:InvestorType:National"
                @click="redirectToNationalRegister"
              >
                {{ $t("br_investor") }}
              </v-btn>
              <v-btn
                color="primary"
                data-test="WelcomePage:InvestorType:International"
                @click="redirectToInternationalRegister"
              >
                {{ $t("us_investor") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import PartnerService from "@/services/PartnerService";
import DxaLink from "@/components/dxa/Link/DxaLink.vue";
import SideMenu from "@/components/dxa/LandingPage/SideMenu.vue";
import HeaderMenuLink from "@/components/dxa/LandingPage/HeaderMenuLink.vue";
import { mapState, mapMutations } from "vuex";
import LanguageSwitch from "@/components/dxa/LanguageSwitch/LanguageSwitch.vue";
import mixpanel from "mixpanel-browser";
import { getAlphaRegisterUrlLang } from "@/shared/helpers/alphaRegisterHelpers";
import { openOutsideUrls } from "@/shared/helpers/openOutsideUrls";

const displayEnum = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};

const displayBreakpoints = {
  small: {
    embedVideoWidth: 320,
    embedVideoHeight: 180,
  },
  medium: {
    embedVideoWidth: 400,
    embedVideoHeight: 225,
  },
  large: {
    embedVideoWidth: 550,
    embedVideoHeight: 315,
  },
};

export default {
  components: {
    SideMenu,
    HeaderMenuLink,
    LanguageSwitch,
  },
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      ps: new PartnerService(),
      partner: null,
      partnerLogo: null,
      display: displayEnum.MEDIUM,
      displayEnum: displayEnum,
      embedVideoWidth: displayBreakpoints.large.embedVideoWidth,
      embedVideoHeight: displayBreakpoints.large.embedVideoHeight,
      getAlphaRegisterUrlLang,
      openOutsideUrls,
      chooseRegisterDialog: false,
      envAllowedRegisterInternational:
        process.env.VUE_APP_INTERNATIONAL_REGISTER,
      envUrlRegisterInternational:
        process.env.VUE_APP_REACT_INVESTOR_PLATFORM_BASE_URL,
    };
  },
  async created() {
    this.setAppBar(false);
    let partner_name = this.$route.query.partner;
    const bankerId = this.$route.query.banker;
    let partner = await this.ps.get_partner_configs(partner_name);
    let parsedPartner = JSON.parse(partner);
    const pagePath = this.$route.fullPath;
    this.partnerLogo = parsedPartner.CompanyLogoPath;
    this.checkLanguage();
    this.onResize();
    window.addEventListener("resize", this.onResize);
    let investorFormData = {
      ...this.$route.query,
    };
    localStorage.setItem("investorFormData", JSON.stringify(investorFormData));

    const pagePathLang = this.getAlphaRegisterUrlLang(pagePath);
    if (pagePathLang) {
      this.$i18n.locale = pagePathLang;
      this.$store.commit("SET_LANGUAGE", pagePathLang);
    } else {
      this.$i18n.locale = "en";
      this.$store.commit("SET_LANGUAGE", "en");
    }

    // DISPARANDO EVENTO SOMENTE QUANDO TIVER ID DO BANKER
    if (bankerId && parsedPartner.Id) {
      mixpanel.track("InvestorLanding_View", {
        partner_id: parsedPartner.Id,
        banker_id: bankerId,
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.setAppBar(true);
  },

  computed: {
    ...mapState(["lang"]),
  },

  methods: {
    ...mapMutations({
      setAppBar: "SET_APP_BAR",
    }),
    checkLanguage() {
      let { lang } = this.$route.query;
      if (lang) {
        this.$store.commit("SET_LANGUAGE", lang);
      } else {
        let localStorageLang = window.localStorage.getItem("lang");
        if (!localStorageLang) {
          this.$store.commit("SET_LANGUAGE", "pt");
        }
      }
    },
    handleGoLinkedin() {
      this.openOutsideUrls(
        "https://www.linkedin.com/company/dealboard-brasil/",
        "https://www.linkedin.com/company/dealboard-global"
      );
    },
    redirect_to_login() {
      this.$gtag.event("login_redirect", {
        event_label: "login_welcome_page",
        value: 0,
      });
      this.$router.push("/auth");
    },
    redirectToNationalRegister() {
      this.$gtag.event("welcome_page", {
        event_category: "investor_register",
        event_label: this.$route.query.partner,
        value: 0,
      });

      this.$router.push({
        path: `/auth/register-client`,
        query: this.$route.query,
      });
    },
    redirectToInternationalRegister() {
      const partner = this.$route.query.partner;
      const bankerId = this.$route.query.banker;

      if (!partner) {
        this.$toast.erro(this.$t("an_error_ocurred"));
        return;
      }
      let url = `${this.envUrlRegisterInternational}register?partner=${partner}`;

      if (bankerId && bankerId.length) {
        url = `${url}&bankerId=${bankerId}`;
      }

      window.location.href = url;
    },
    redirectToRegister() {
      if (this.envAllowedRegisterInternational === "TRUE") {
        this.chooseRegisterDialog = true;
        return;
      }

      this.redirectToNationalRegister();
    },
    handleGoAuth() {
      this.$router.push("/auth");
    },
    handlePrivacyPolicy() {
      this.openPrivacyPolicy();
    },
    onResize() {
      if (window.innerWidth <= 320) {
        this.display = displayEnum.SMALL;
        this.embedVideoWidth = displayBreakpoints.small.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.small.embedVideoHeight;
      } else if (window.innerWidth <= 768) {
        this.display = displayEnum.SMALL;
        this.embedVideoWidth = displayBreakpoints.medium.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.medium.embedVideoHeight;
      } else if (window.innerWidth < 1024) {
        this.display = displayEnum.MEDIUM;
        this.embedVideoWidth = displayBreakpoints.medium.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.medium.embedVideoHeight;
      } else {
        this.display = displayEnum.LARGE;
        this.embedVideoWidth = displayBreakpoints.large.embedVideoWidth;
        this.embedVideoHeight = displayBreakpoints.large.embedVideoHeight;
      }
    },
  },
};
</script>
<style>
.crisp-client {
  display: none;
}
.opacity-on {
  display: none;
}
</style>
<style scoped>
.main-container-bg {
  background-color: var(--white);
  width: 100%;
  z-index: -2;
}
.main-container {
}
.desktop {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  background-image: url("../../../../../../assets/dxa-background-welcome-main.png");
}
.cartao {
  background-image: url("../../../../../../assets/mobile-welcome-page.png");
  background-size: cover;
  background-color: rgba(253, 252, 251, 0);
  background-color: transparent;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn-gradient:focus {
  box-shadow: 0px 4px 22px var(--primary);
}
.btn-gradient {
  font-weight: bold;
  color: var(--white);
  background: var(--primary) !important;
  border-radius: 16px;
}
.btn-gradient:hover {
  background-color: var(--primary-hover) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}
.btn-gradient:active {
  background-color: var(--primary-focus) !important;
}

.btn-registered {
  background-color: transparent !important;
  z-index: 15 !important;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.btn-registered:hover {
  color: var(--primary-hover);
}

.info-div {
  max-width: 50%;
}

.info-div-title {
  font-size: 44px;
}

.link-redirect-login {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alpha-register-title {
  line-height: 1.2;
}

.linkedin-icon {
  display: block;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  height: max-content;
}

.linkedin-icon:hover {
  background: #f3f4f6;
}

.alpha-register-subtitle {
  line-height: 1.25;
}

@media screen and (max-width: 1368px) {
  .info-div {
    max-width: 60% !important;
    margin-left: 2rem;
  }
  .info-div-title {
    font-size: 40px !important;
  }
}

.nav-bar {
  background-color: #121212;
  height: 70px;
  top: 0;
  width: 85%;
  position: absolute;
  z-index: 1;
}

.partner-logo-dxa {
  width: auto;
  height: auto;
  max-width: 285px;
  max-height: 50px;
  margin-left: -8px;
}

.redirect-button-container {
  width: 42%;
  margin-top: 40px;
}

.redirect-footer-container {
  margin: 64px auto 0 auto;
}

.number-group-dxa-logo-sub-title {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #9ca3af;
  display: block;
  margin-top: 20px;
}

.number-group-dxa-logo {
  display: grid;
  place-items: center;
  margin-bottom: 14px;
}

.img-section2 {
  margin-left: -160px;
}

.header {
  background: #1a56db;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 40px;
  max-width: 1280px;
}

.header-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1a56db;
}

.logo {
  width: 200px;
}

.button-group {
  display: flex;
  gap: 16px;
  align-items: center;
}

.header-button {
  border-radius: 10px;
}

.button-label-white {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
}

.button-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

/* standardize buttons type full */
.link-filled-size {
  min-width: 324px;
  max-width: max-content;
  padding: 15px 20px;
}

.container {
  background-color: var(--white);
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  position: relative;
}

.section1 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 54px;
  padding: 40px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-color: var(--white);
  z-index: 99;
  align-items: center;
  max-width: 1280px;
}

.section-1-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.primary-text {
  color: var(--primary);
  font-family: Source Sans Pro;
}

.header-text {
  font-family: Source Sans Pro;
  font-weight: bold;
  font-size: 35px;
  color: var(--white);
}

.header-text :nth-child(1) {
  margin-right: 8px;
}

.header-subtitle {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #6b7280;
  margin-top: 8px;
}

.section1-content {
  width: 540px;
}

.action-button {
  font-family: Source Sans Pro;
  margin-top: 40px;
  font-size: 18px;
}

.partner-row {
  margin-top: 30px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
  padding-left: 40px;
}

.partner-footer {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
}

.partner-footer-content {
  width: 50%;
  text-align: center;
  margin-top: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9fafb;
  border-radius: 16px;
  max-width: 550px;
  min-height: 440px;
  z-index: 1;
  border-bottom: 1px solid #f3f4f6;
  border-top: 1px solid #f3f4f6;
}

.card-container-mobile {
  border-bottom: 1px solid #f3f4f6;
  border-top: 1px solid #f3f4f6;
  background: #f9fafb;
  border-radius: 16px;
  min-height: 440px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
}

.header-label {
  text-align: center;
  padding: 20px;
}

.card-header-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #111928;
}

.card-header-text {
  margin-top: 10px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #6b7280;
  display: flex;
  flex-direction: column;
}

.card-header-text-bold {
  font-family: Source Sans Pro;
  font-weight: bold;
}

.card-content-row {
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 80%;
}

.company-name {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-growth {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-details {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
}

.progress-border {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-secondary {
  width: 9%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
  display: flex;
  align-items: center;
}

.progress-secondary-19 {
  width: 19%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
}

.progress-secondary-5 {
  width: 5%;
  height: 10px;
  border-radius: 8px;
  background-color: #9c9c9c;
}

.progress-primary {
  width: 100%;
  height: 10px;
  border-radius: 8px;
  background-color: var(--primary);
}

.company-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.company-market-compare-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;
}

.company-market-compare {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-market-compare-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.company-market-compare-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.market-sources {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.market-sources-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-left: 5px;
}

.card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-footer-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.separator {
  border: 1px solid var(--dark);
  width: 100%;
  margin: 20px 0;
}

.section2 {
  display: flex;
  padding: 30px 15px;
  background-color: #1a56db;
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
}

.section-2-container {
  display: grid;
  width: 100%;
  place-items: center;
  margin: 96px 0;
  background: #1a56db;
  padding-top: 60px;
  padding-bottom: 60px;
}

.section3 {
  display: flex;
  background-color: var(--white);
  padding: 30px 0;
  justify-content: space-around;
  background-image: url("../../../../../../assets/tc-landing/bg-video.svg"),
    url("../../../../../../assets/tc-landing/xx.png");
  background-position: 0 20%, bottom right 5%;
  background-repeat: no-repeat;
  height: 600px;
}

.section2-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.number {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--white);
}

.number-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #9ca3af;
}

.number-group {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 20px;
  justify-content: space-around;
}

.embed-video-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.how-it-works-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 35px;
  width: 40%;
}

.how-it-works {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.how-it-works-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: var(--white);
}

.video-action-button {
  width: 326px;
}

.section4 {
  display: flex;
  padding: 96px 0;
  background-color: #f9fafb;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.steps-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #111928;
}

.steps-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 64px;
}

.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
  margin-top: 30px;
  height: 10%;
}

.step-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.15px;
  color: #111928;
  margin-top: 20px;
}

.step-number-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 48px;
  background-color: #e1effe;
  height: 48px;
}

.step-number {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
}

.step-description {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: #6b7280;
  margin-top: 8px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 30px;
  background-color: var(--white);
}

.content {
  display: flex;
  flex-direction: column;
}

.footer-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #6b7280;
  margin-bottom: 24px;
}

.footer-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

.footer-c {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #6b7280;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  margin-top: 20px;
}

.footer-c span.link {
  display: block;
  text-decoration: underline;
  cursor: pointer;
}

.footer-c span.link:hover {
  transition: all;
  font-weight: bold;
}

.footer-label-containter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* to break the text of step-description */
.step-description-break-line {
  display: block;
  margin-top: 1rem;
}

.create-wallet {
  white-space: nowrap !important;
  font-weight: normal;
  border: 1px solid var(--white);
}

.create-wallet:hover {
  filter: drop-shadow(0px 1px 1px var(--white)) !important;
}

.graphic-container img {
  max-width: 100%;
  margin-top: 60px;
}

.login-in-link {
  font-size: 14px;
  display: block;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
}

.login-in-link:hover {
  text-decoration: underline;
}

.header-text h2.title {
  font-size: 60px !important;
  font-weight: bold;
  line-height: 60px;
  color: #111928;
}

.number-container-logo {
  margin-bottom: 48px;
  max-width: max-content;
  margin-left: 5%;
}

.number-container-logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container-mobile {
  display: none;
}

.header-mobile {
  display: none;
}
.graphic-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.graphic-image-content {
  max-width: max-content;
}

.graphic-source {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #9ca3af;
  font-size: 10px;
}

.graphic-info-container {
  margin-top: 8px;
}

.graphic-source-info-icon {
  width: 6px;
  height: 6px;
  border: 1px solid #9ca3af;
  border-radius: 100%;
  color: #9ca3af;
  padding: 1px;
}

.graphic-info-footer {
  display: flex;
  justify-content: center;
  margin-top: 14px;
  gap: 12px;
}

.graphic-info-footer-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.graphic-info-footer-label span.circle {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 100%;
}

.graphic-info-footer-label span.text {
  font-size: 12px;
  font-weight: 500;
  color: #111928;
  display: block;
}

.graphic-info-footer-label span.blue {
  background-color: #3f83f8;
}

.graphic-info-footer-label span.gray {
  background-color: #d9d9d9;
}

.button-choose-register-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}

.button-choose-register-container > button {
  font-size: 14px !important;
}

.info-choose-register {
  color: var(--dark);
  font-size: 16px;
  text-align: center;
  max-width: 480px;
  margin-left: auto;
  margin: auto;
  margin-top: 12px;
  word-break: keep-all;
}

.choose-register-dialog-header {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 8px;
}

/* MOBILE */
@media only screen and (max-width: 1020px) {
  .header-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .card-container-mobile {
    display: flex;
  }

  .card-container {
    display: none;
  }

  .section4 {
    padding: 32px 0px;
  }

  .header-container {
    align-items: flex-start;
    margin-bottom: 56px;
  }

  .header-text h2.title {
    font-size: 30px !important;
    line-height: 30px;
  }

  .header-subtitle {
    font-size: 16px;
    line-height: 1.5;
  }

  .redirect-button-container {
    margin-top: 10px;
  }

  .how-it-works-label {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--white);
  }

  .dxa-logo-top {
    display: none;
  }

  .main-container {
    padding: 0;
  }

  .partner-logo-dxa {
    margin-left: 0px;
  }

  .link-filled-size {
    margin-bottom: 40px;
  }

  .card-container {
    justify-self: center;
    align-self: center;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    width: initial;
    padding: 0px;
    justify-content: center;
    justify-items: flex-start;
  }

  .redirect-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .redirect-footer-container {
    margin: 40px 0 0 0;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
    height: auto;
  }

  .section1 {
    flex-direction: column;
    z-index: 0;
    padding-top: 0;
    gap: 32px;
    padding-bottom: 0px;
  }

  .card-container {
    width: 100%;
  }

  .partner-logo-dxa-top {
    margin-left: 70px;
  }

  .section-2-container {
    margin-top: 32px;
  }

  .section1-bg1 {
    top: 86%;
    right: -10%;
  }

  .section1-bg2 {
    bottom: 45%;
  }

  .header-text {
    text-align: center;
  }

  .header-subtitle {
    text-align: center;
  }

  .section1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partner-row {
    width: 100%;
    justify-content: space-evenly;
  }

  .partner-footer-content {
    width: 100%;
    margin-bottom: 55px;
  }

  .partner-footer {
    font-size: 16px;
  }

  .header-label {
    width: 100%;
    text-align: center;
    padding: 0px 10px;
  }

  .img-section2 {
    display: none;
  }

  .number-group {
    display: grid;
    margin-left: 0px;
  }

  .section-2-container {
    padding: 32px 0px;
    margin-bottom: 32px;
  }

  .number-container {
    margin-bottom: 40px;
  }

  .number-container-logo {
    margin-top: 8px;
  }

  .number-container:first-child {
    margin: 20px 0 40px 0;
  }

  .section3 {
    flex-direction: column;
    background-image: url("../../../../../../assets/tc-landing/bg-video.svg");
    background-position: center 9%;
    background-repeat: no-repeat;
    background-size: 20rem;
    height: auto;
    padding: 30px 0 0 0;
  }

  .embed-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
  }

  .how-it-works-container {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    padding-bottom: 0;
    margin-top: 100px;
  }

  .section4 {
    flex-direction: column;
  }

  .steps-row {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .step-container {
    width: 100%;
    padding: 15px 30px;
    margin-top: 0;
  }

  .how-it-works {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--primary);
    margin-top: 20px;
  }

  .section2 {
    background-position: bottom left -60px, center right -27px;
    background-repeat: no-repeat;
    background-size: 9rem, 7rem;
    padding: 0;
  }

  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    margin-right: 0;
  }

  .action-button {
    width: 100%;
  }

  .section3-action-button {
    width: 80%;
  }

  .button-group {
    width: 40%;
    display: none;
  }

  /* standardize buttons type full */
  .link-filled-size {
    min-width: initial;
    max-width: 220px;
  }

  .step-label {
    font-size: 22px;
  }

  .footer-c {
    flex-direction: column;
    gap: 0.2rem;
  }

  .footer {
    padding: 32px 0px;
  }

  .card-header-title {
    font-size: 24px;
  }

  .card-header-text {
    font-size: 16px;
  }

  .logo {
    max-width: 100%;
  }

  .graphic-container img {
    max-width: 100%;
    margin-top: 40px;
  }

  .card-container {
    min-height: max-content;
  }
}

@media only screen and (max-width: 520px) {
  .button-choose-register-container {
    flex-direction: column;
  }

  .button-choose-register-container > button {
    width: 220px !important;
  }
}
</style>
<style>
.app_bar {
  z-index: 2;
}

.btn_lang {
  margin-top: 10px !important;
}

.back_btn {
  z-index: 3;
}
</style>
